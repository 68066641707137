import { track } from '@/analytics/trackingQueue'
import { isOnServer } from '@shared/constants/util'
import { AnalyticsEvent } from '@shipt/analytics-member-web'
import { urlPathnameToLocationName } from '@/analytics/utils'

export const trackEnteredZipCode = (zip_code: string) => {
  if (isOnServer()) return
  const url = window.location.href
  const eventProperties = {
    url,
    zip_code,
  }
  track({
    eventName: AnalyticsEvent.EnteredZipCode,
    properties: eventProperties,
  })
}

export const trackLiveChatConversationStarted = () => {
  track({
    eventName: AnalyticsEvent.LiveChatConversationStarted,
    properties: {
      location: urlPathnameToLocationName(),
    },
  })
}

export const trackLiveChatConversationEnded = () => {
  track({
    eventName: AnalyticsEvent.LiveChatConversationEnded,
    properties: {
      location: urlPathnameToLocationName(),
    },
  })
}
