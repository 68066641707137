import { Chat } from '@/components/Chat'
import { type CMSPageDataWithSpecificLayout } from '@/components/CMS/types'
import { GapManager } from '@/components/CMS/components/GapManager'
import { getCMSWrapperProps } from '@/components/CMS/utils/getCMSWrapperProps'
import { CMSLayoutContextProvider } from '@/components/CMS/CMSLayoutContext'
import { PageLayoutCMSSection } from '@/components/CMS/PageLayoutCMSSection'

export const PageLayout = ({
  layoutData: { id, content_type_id, data },
}: {
  layoutData: CMSPageDataWithSpecificLayout<'page_layout'>['content']
}) => (
  <CMSLayoutContextProvider data={content_type_id}>
    <div {...getCMSWrapperProps({ content_type_id, id })}>
      {data?.announcement && (
        <PageLayoutCMSSection section={data.announcement} />
      )}
      {data?.header && <PageLayoutCMSSection section={data.header} />}
      {data?.body && (
        <GapManager verticalSpacing={data.vertical_spacing}>
          <PageLayoutCMSSection section={data.body} />
        </GapManager>
      )}
      {data?.footer && <PageLayoutCMSSection section={data.footer} />}
      <Chat />
    </div>
  </CMSLayoutContextProvider>
)
