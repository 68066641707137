import Script from 'next/script'
import { scripts } from '@/lib/ThirdPartyScripts/scripts'
import { type Dispatch, type SetStateAction } from 'react'

type Props = {
  setIsSprinklrLoaded: Dispatch<SetStateAction<boolean>>
}

export function SprinklrChat({ setIsSprinklrLoaded }: Props) {
  const nonce = process.env.NEXT_PUBLIC_NONCE_PLACEHOLDER

  return (
    <Script
      id="sprinklr"
      nonce={nonce}
      dangerouslySetInnerHTML={{
        __html: scripts.sprinklr,
      }}
      strategy="lazyOnload"
      onReady={() => setIsSprinklrLoaded(true)}
    />
  )
}
